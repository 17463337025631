<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} ITEM</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">
      <!-- NAME -->
      <div class="p-6">
        <vs-input
          :label="$t('NameEn')"
          v-model="NameEN"
          class="mt-5 w-full"
          name="nameen"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('nameen')">{{
          errors.first("name_en")
        }}</span>

        <vs-input
          :label="$t('NameAr')"
          v-model="NameAR"
          class="mt-5 w-full"
          name="namear"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('namear')">{{
          errors.first("name_ar")
        }}</span>
      </div>

      <!-- Image -->
      <!-- <vs-card title="icon" class="mt-5">
        <h4>{{ $t("Image") }}</h4>
        <template v-if="ImagePath">
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="baseURL + ImagePath" alt="img" class="responsive" />
          </div>
        </template>
        <div class="modify-img flex justify-between mt-5">
          <input
            type="file"
            class="hidden"
            ref="updateImgInput"
            @change="updateCurrImg"
            accept="image/*"
          />
          <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">{{
            $t("UpdateImage")
          }}</vs-button>
          <vs-button type="flat" color="#999" @click="ImagePath = null">{{
            $t("RemoveImage")
          }}</vs-button>
        </div>
      </vs-card> -->

      <vs-card title="icon" class="mt-5">
        <h4>{{ $t("Image") }}</h4>
        <template v-if="ImagePath">
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="baseURL + ImagePath" alt="img" class="responsive" />
          </div>
        </template>
        <vs-button color="primary" type="filled" @click="toggleShow">set Image</vs-button>
        <div class="modify-img justify-between mt-5">
          <my-upload
            field="img"
            @crop-success="cropSuccess"
            @crop-upload-success="cropUploadSuccess"
            @crop-upload-fail="cropUploadFail"
            :noCircle="true"
            v-model="show"
            :value="false"
            :withCredentials="false"
            :width="250"
            :height="300"
            :url="uploadUrl"
            lang-type="en"
            :params="params"
            :headers="headers"
            img-format="png"
          ></my-upload>
          <!-- <img class="text-center" :src="ImagePath" /> -->
        </div>
      </vs-card>

      <!-- COLOR -->
      <div class="p-6">
        <vs-input
          :label="$t('COLOR')"
          v-model="BgColor"
          class="mt-5 w-full"
          name="color"
          v-validate="'required'"
          type="color"
        />
            <vs-input
          v-model="BgColor"
          class="mt-5 w-full"
          name="color"
          v-validate="'required'"

        />
        <span class="text-danger text-sm" v-show="errors.has('color')">{{
          errors.first("color")
        }}</span>
      </div>

      <!-- Icon Image -->
      <vs-card title="icon" class="mt-5">
        <h4>{{ $t("IconImage") }}</h4>
        <template v-if="IconImagePath">
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img
              :src="baseURL + IconImagePath"
              alt="img"
              class="responsive"
              style="background-color: #e7e9eb"
            />
          </div>
        </template>
        <div class="modify-img flex justify-between mt-5">
          <input
            type="file"
            class="hidden"
            ref="updateCurrIconImg"
            @change="updateCurrIconImg"
            accept="image/*"
          />
          <vs-button class="mr-4" type="flat" @click="$refs.updateCurrIconImg.click()">{{
            $t("UpdateImage")
          }}</vs-button>
          <vs-button type="flat" color="#999" @click="IconImagePath = null">{{
            $t("RemoveImage")
          }}</vs-button>
        </div>
      </vs-card>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">{{
        $t("Save")
      }}</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{
        $t("Cancel")
      }}</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain } from "@/gloabelConstant.js";
import myUpload from "vue-image-crop-upload/upload-2.vue";
import EncryptionHandler from "@/http/requests/auth/jwt/EncryptionHandler.js";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { ID, NameEN, NameAR, ImagePath, BgColor, IconImagePath } = JSON.parse(
          JSON.stringify(this.data)
        );
        this.ID = ID;
        this.NameEN = NameEN;
        this.NameAR = NameAR;
        this.ImagePath = ImagePath;
        this.BgColor = BgColor;
        this.IconImagePath = IconImagePath;
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.ID, this.Name, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },

  data() {
    return {
      show: false,
      baseURL: domain,
      uploadUrl: domain + "API/Common/UploadFile",
      ID: null,
      NameEN: "",
      NameAR: "",
      ImagePath: "",
      BgColor: "",
      IconImagePath: "",
      params: {
        token: "123456798",
        name: "avatar",
      },
      headers: {
        smail: "*_~",
      },
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    isFormValid() {
      return !this.errors.any() && this.NameEN;
    },
  },

  methods: {
    toggleShow() {
      //this.ImagePath = "";
      this.show = !this.show;
      debugger;
    },
    cropSuccess(ImagePath, field) {
      console.log("-------- crop success --------");
      this.ImagePath = ImagePath;
      debugger;
    },
    cropUploadSuccess(jsonData, field) {
      this.ImagePath = EncryptionHandler.decrypt(jsonData);
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
      debugger;
    },
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
      debugger;
    },
    initValues() {
      if (this.data.ID) return;
      this.ID = null;
      this.NameAR = "";
      this.NameEN = "";
    },
    updateCurrImg(input) {
      this.$store.dispatch("updateCurrImg", input).then((response) => {
        debugger;
        this.ImagePath = response.data;
      });
    },
    updateCurrIconImg(input) {
      this.$store.dispatch("updateCurrImg", input).then((response) => {
        debugger;
        this.IconImagePath = response.data;
      });
    },
    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const obj = {
            ID: this.ID,
            NameEN: this.NameEN,
            NameAR: this.NameAR,
            ImagePath: this.ImagePath,
            BgColor: this.BgColor,
            IconImagePath: this.IconImagePath,
          };

          if (this.ID !== null && this.ID >= 0) {
            this.$store
              .dispatch("MedicalPackageCategoryList/UpdateMedicalPackageCategory", obj)
              .catch((err) => {
                console.error(err);
                window.showError(err.response.data.message);
              });
          } else {
            delete obj.ID;
            obj.popularity = 0;
            this.$store
              .dispatch("MedicalPackageCategoryList/AddMedicalPackageCategory", obj)
              .catch((err) => {
                console.error(err);
                window.showError(err.response.data.message);
              });
          }

          this.$emit("closeSidebar");
          this.initValues();
          //this.$store.dispatch("foodTypeList/fetchfoodTypeListItems");
        }
      });
    },
  },

  components: {
    VuePerfectScrollbar,
    myUpload,
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
